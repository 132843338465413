@media print {
  .print-avoid-block-break {
    page-break-inside: avoid !important;
  }

  .print-avoid-after-break {
    page-break-after: avoid !important;
  }

  p,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5 {
    widows: 3 !important;
    orphans: 4 !important;
  }

  @page {
    margin: 0;
  }

  body {
    padding: 0 !important;
  }

  .cdk-overlay-container,
  alliance-employer-mobile-header,
  alliance-chat-shell-sidebar,
  alliance-shared-footer {
    display: none;
  }

  #work-ua-ats-extension-root {
    display: none;
  }
}
