/* stylelint-disable at-rule-no-unknown */
@import url('tailwindcss/base');
@import url('tailwindcss/utilities');
@import url('tailwindcss/components');
@import url('_fonts.css');
@import url('_plain-markup.tw.css');
@import url('_list.tw.css');
@import url('@angular/cdk/overlay-prebuilt.css');

@layer components {
  .santa-online-label::before {
    @apply santa-rounded-full;
    @apply santa-bg-green-500;
    @apply santa-inline-block;

    content: '';
    width: 6px;
    height: 6px;
    margin-right: 4px;
  }

  .santa-sentence-case {
    @apply santa-lowercase;
  }

  .santa-sentence-case::first-letter {
    @apply santa-uppercase;
  }
}
