/* stylelint-disable at-rule-no-unknown */

@layer components {
  .santa-list li,
  .santa-list-red li {
    @apply santa-list-none;
    @apply santa-relative;
    @apply santa-mt-10;
  }

  .santa-list-red li::before {
    content: '\2B25';
    @apply santa-text-12;
    @apply santa-absolute;

    left: -20px;
    @apply santa-text-red-500;
  }

  .santa-list li::before {
    content: '\2B25';
    @apply santa-text-12;
    @apply santa-absolute;

    left: -20px;
  }
}
