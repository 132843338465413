.santa-plain-markup *,
*[ng-version] .santa-plain-markup p {
  @apply santa-font-gregular;
  @apply santa-leading-20;
}

.santa-plain-new-markup,
.santa-plain-new-markup *,
*[ng-version] .santa-plain-new-markup p {
  @apply santa-font-gregular;
  @apply santa-typo-regular;
}

.santa-plain-new-markup,
*[ng-version] .santa-plain-new-markup a {
  @apply santa-typo-regular;
  @apply santa-text-black-500;
}

.santa-plain-new-markup,
*[ng-version] .santa-plain-new-markup ul li,
*[ng-version] .santa-plain-new-markup ol li {
  @apply santa-typo-regular;
}

@media only screen and (min-width: theme('screens.760')) {
  .santa-plain-new-markup,
  *[ng-version] .santa-plain-new-markup p {
    @apply santa-typo-large;
  }

  .santa-plain-new-markup,
  *[ng-version] .santa-plain-new-markup a {
    @apply santa-typo-large;
  }

  .santa-plain-new-markup,
  *[ng-version] .santa-plain-new-markup span {
    @apply santa-typo-large;
  }

  .santa-plain-new-markup,
  *[ng-version] .santa-plain-new-markup ul li,
  *[ng-version] .santa-plain-new-markup ol li {
    @apply santa-typo-large;
  }
}

.santa-plain-markup b,
.santa-plain-markup strong,
.santa-plain-markup b *,
.santa-plain-markup strong *,
.santa-plain-markup [style*='font-weight: bold'],
.santa-plain-markup [style*='font-weight: 700'],
.santa-plain-markup [style*='font-weight:bold'],
.santa-plain-markup [style*='font-weight:700'],
.santa-plain-markup p[style*='font-weight: bold'],
.santa-plain-markup p[style*='font-weight: 700'],
.santa-plain-markup p[style*='font-weight:bold'],
.santa-plain-markup p[style*='font-weight:700'] {
  @apply santa-font-bold;
  @apply santa-font-gbold;
}

.santa-plain-markup ul,
.santa-plain-markup ol {
  @apply santa-m-0;
  @apply santa-p-0;
  @apply santa-mt-10;
  @apply santa-list-none;
}

.santa-plain-markup li {
  @apply santa-relative;
  @apply santa-p-0;

  list-style-position: inside;
}

.santa-plain-markup ul li {
  padding-left: 30px;
}

.santa-plain-markup ol li {
  list-style-type: decimal;
}

.santa-plain-markup ol li::before {
  content: '';
  padding-right: 12px;
}

.santa-plain-markup ul li::before {
  content: '';
  width: 6px;
  height: 6px;
  @apply santa-bg-black-700;
  @apply santa-absolute;

  top: 6px;
  left: 6px;
  transform: rotate(45deg);
}

.santa-plain-markup ul li p,
.santa-plain-markup ol li p {
  display: inline;
}

.santa-plain-new-markup ul li::before {
  top: 10px;
}

.santa-plain-markup p:not(:first-child) {
  @apply santa-mt-20;
}

.santa-plain-markup *:first-child {
  @apply santa-mt-0;
}
