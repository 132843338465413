a {
  @apply santa-no-underline;

  color: inherit;
}

html,
body {
  @apply santa-h-full;
  @apply santa-box-border;
}

h1,
h2,
h3,
h4,
h5,
p {
  @apply santa-m-0;
  @apply santa-p-0;
}

body {
  @apply santa-font-gregular;
  @apply santa-m-0;
  @apply santa-p-0;
}

textarea {
  @apply santa-font-gregular;
}

strong,
b {
  @apply santa-font-gbold;
}

button,
button:focus {
  @apply santa-m-0;
  @apply santa-p-0;
  @apply santa-border-0;
  @apply santa-outline-none;
}
