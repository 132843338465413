@import url('./assets/normalize.tw.css');
@import url('./assets/media-print.css');

body.chat-sidebar-offset alliance-employer-new-header > .santa-fixed,
body.chat-sidebar-offset .main-content-wrapper {
  margin-right: 60px !important;
}

body.chat-sidebar-offset .header-wrapper.fixed {
  @apply santa-box-border;

  padding-right: 60px !important;
}

.skiptranslate {
  display: none;
}
