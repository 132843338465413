@font-face {
  font-family: 'Graphik Light';
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Light-Web.eot');
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Light-Web.eot#iefix') format('embedded-opentype'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Light-Web.woff2') format('woff2'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Light-Web.woff') format('woff'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Light-Web.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Graphik Regular';
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Regular-Web.eot');
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Regular-Web.eot#iefix') format('embedded-opentype'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Regular-Web.woff2') format('woff2'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Regular-Web.woff') format('woff'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Regular-Web.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Graphik Medium';
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Medium-Web.eot');
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Medium-Web.eot#iefix') format('embedded-opentype'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Medium-Web.woff2') format('woff2'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Medium-Web.woff') format('woff'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Medium-Web.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Graphik Semibold';
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Semibold-Web.eot');
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Semibold-Web.eot#iefix') format('embedded-opentype'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Semibold-Web.woff2') format('woff2'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Semibold-Web.woff') format('woff'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Semibold-Web.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Graphik Bold';
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Bold-Web.eot');
  src: url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Bold-Web.eot#iefix') format('embedded-opentype'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Bold-Web.woff2') format('woff2'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Bold-Web.woff') format('woff'),
    url('https://images.cf-rabota.com.ua/2017/05/fonts/graphik/Graphik-Bold-Web.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
